import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_list_header = _resolveComponent("ion-list-header")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_content = _resolveComponent("ion-content")!

  return (_openBlock(), _createBlock(_component_ion_content, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_list, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_list_header, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.getProductStore(_ctx.currentProductStore.productStoreId).storeName || _ctx.currentProductStore.productStoreId), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_ion_item, {
            button: "",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.togglePrimary()))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.translate("Primary")) + " ", 1),
              _createVNode(_component_ion_icon, {
                slot: "end",
                color: _ctx.current.primaryFacilityGroupId === _ctx.shopifyShopIdForProductStore(_ctx.currentProductStore.productStoreId) ? 'warning' : '',
                icon: _ctx.current.primaryFacilityGroupId === _ctx.shopifyShopIdForProductStore(_ctx.currentProductStore.productStoreId) ? _ctx.star : _ctx.starOutline
              }, null, 8, ["color", "icon"])
            ]),
            _: 1
          }),
          _createVNode(_component_ion_item, {
            button: "",
            lines: "none",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.removeStoreFromFacility()))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.translate("Unlink")) + " ", 1),
              _createVNode(_component_ion_icon, {
                slot: "end",
                icon: _ctx.removeCircleOutline
              }, null, 8, ["icon"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}