export const SN_FACILITY = 'facility'
export const FACILITY_LIST_UPDATED = SN_FACILITY + '/LIST_UPDATED'
export const FACILITY_QUERY_UPDATED = SN_FACILITY + '/QUERY_UPDATED'
export const FACILITY_GROUP_QUERY_UPDATED = SN_FACILITY + '/GROUP_QUERY_UPDATED'
export const FACILITY_CURRENT_UPDATED = SN_FACILITY + '/CURRENT_UPDATED'
export const FACILITY_LOCATIONS_UPDATED = SN_FACILITY + '/LOCATIONS_UPDATED'
export const FACILITY_POSTAL_ADDRESS_UPDATED = SN_FACILITY + '/POSTAL_ADDRESS_UPDATED'
export const FACILITY_TELECOM_AND_EMAIL_ADDRESS_UPDATED = SN_FACILITY + '/TELECOM_AND_EMAIL_ADDRESS_UPDATED'
export const FACILITY_MAPPINGS_UPDATED = SN_FACILITY + '/MAPPINGS_UPDATED'
export const FACILITY_SHOPIFY_MAPPINGS_UPDATED = SN_FACILITY + '/SHOPIFY_MAPPINGS_UPDATED'
export const FACILITY_CALENDAR_UPDATED = SN_FACILITY + '/CALENDAR_UPDATED'
export const FACILITY_PARTIES_UPDATED = SN_FACILITY + '/PARTIES_UPDATED'
export const FACILITY_PRODUCT_STORES_UPDATED = SN_FACILITY + '/PRODUCT_STORES_UPDATED'
export const FACILITY_VIRTUAL_FACILITY_LIST_UPDATED = SN_FACILITY + '/VIRTUAL_FACILITY_LIST_UPDATED'
export const FACILITY_GROUP_LIST_UPDATED = SN_FACILITY + '/GROUP_LIST_UPDATED'
export const FACILITY_ARCHIVED_UPDATED = SN_FACILITY + '/ARCHIVED_UPDATED'
export const FACILITY_LOGINS_UPDATED = SN_FACILITY + '/LOGINS_UPDATED'
